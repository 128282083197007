
import { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  MiniMap,
  Background,
  OnNodesChange,
  OnEdgesChange,
  NodeMouseHandler,
  Edge,
  Controls,
} from '@xyflow/react';


import CustomNode from './nodes/CustomNode';
import useAnimatedNodes from './nodes/useAnimatedNodes';
import useExpandCollapse from './nodes/useExpandCollapse';

import '@xyflow/react/dist/style.css';
import { ExpandCollapseNode } from './nodes/types';
import { Phase, Process, Step, WorkflowItem } from '../superadmin.types';

const proOptions = { account: 'paid-pro', hideAttribution: true };

const nodeTypes = {
  custom: CustomNode,
};

type ExpandCollapseProps = {
  treeWidth?: number;
  treeHeight?: number;
  animationDuration?: number;
  process?: Process
  locked?:boolean
  setNodeSelectedData: Function
};

function generateNodes(process: Process) {
  if (!process || !process.workflowItems || process.workflowItems.length === 0) return [];

  return [
    {
      id: `process-${process.id}`,
      data: { name: process.name, expanded: false },
      position: { x: 0, y: 0 },
    },
    ...process.workflowItems.flatMap((workflowItem: WorkflowItem) => [
      {
        id: `w-${workflowItem.id}`,
        data: { 
          name: workflowItem.name, 
          description: workflowItem.description,
          expanded: false 
        },
        position: { x: 0, y: 0 },
      },
      ...workflowItem?.phases?.flatMap((phase: Phase) => [
        {
          id: `w-${workflowItem.id}-phase-${phase.id}`,
          data: { name: phase.name, expanded: false },
          position: { x: 0, y: 0 },
        },
        ...(phase.steps || []).map((step: Step) => ({
          id: `step-${step.id}`, 
          data: {
            id: step.id,
            name: step.name,
            priority: step.priority ?? null,
            description: step.description ?? '',
            externalId: step.externalIds[0]?.externalId ?? null,
            expanded: false,
          },
          position: { x: 0, y: 0 },
        })),
      ]) || [],
    ]),
  ];
};

function generateEdges(process: Process): Edge[] {
  if (!process || !process.workflowItems || process.workflowItems.length === 0)
    return [];

  const edges: Edge[] = [];

  process.workflowItems.forEach((workflowItem) => {
    edges.push({
      id: `e-process-${process.id}-w-${workflowItem.id}`,
      source: `process-${process.id}`,
      target: `w-${workflowItem.id}`,
      type: 'smoothstep',
    });

    workflowItem.phases?.forEach((phase) => {
      edges.push({
        id: `e-w-${workflowItem.id}-phase-${phase.id}`,
        source: `w-${workflowItem.id}`,
        target: `w-${workflowItem.id}-phase-${phase.id}`,
        type: 'smoothstep',
      });

      phase.steps?.forEach((step) => {
        edges.push({
          id: `e-phase-${phase.id}-step-${step.id}`,
          source: `w-${workflowItem.id}-phase-${phase.id}`,
          target: `step-${step.id}`,
          type: 'smoothstep',
        });
      });
    });
  });

  return edges;
}

export function ReactFlowViewProcesses({
  treeWidth = 240,
  treeHeight = 140,
  animationDuration = 400,
  process,
  setNodeSelectedData,
  locked
}: ExpandCollapseProps) {
  const [nodes, setNodes] = useState<ExpandCollapseNode[]>([
    {
      id: 'default',
      data: { name: ' Please Select a Process', expanded: false },
      position: { x: 0, y: 0 },
    },
  ]);

  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    if (process && process.workflowItems && process.workflowItems.length > 0) {
      const updatedNodes = generateNodes(process);
      const updatedEdges = generateEdges(process)
      setNodes(updatedNodes);
      setEdges(updatedEdges);
    }
  }, [process]);

  
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
    nodes,
    edges,
    { treeWidth, treeHeight }
  );
  const { nodes: animatedNodes } = useAnimatedNodes(visibleNodes, {
    animationDuration,
  });

  const onNodesChange: OnNodesChange<ExpandCollapseNode> = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onNodeClick: NodeMouseHandler = useCallback(
    (_, node) => {
      if (locked) {
        console.log("NODES LOCKED", node);
        setNodeSelectedData({...node})
        return;
      }
      
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }
          return n;
        })
      );
    },
    [locked, setNodes, setNodeSelectedData]
  );

  return (
    <ReactFlow
      fitView
      nodes={animatedNodes}
      edges={visibleEdges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeClick={onNodeClick}
      nodeTypes={nodeTypes}
      proOptions={proOptions}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      elementsSelectable={false}
    >
      <Controls />
      <Background />
      <MiniMap />
    </ReactFlow>
  );
}


interface ReactFlowWrapperProps {
  locked: boolean
  setNodeSelectedData: Function
  process: Process
}

function ReactFlowWrapper({ process, locked, setNodeSelectedData }: ReactFlowWrapperProps) {
  return (
    <ReactFlowProvider>
      <ReactFlowViewProcesses process={process} setNodeSelectedData={setNodeSelectedData} locked={locked} />
    </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;
