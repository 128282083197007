import { gql } from '@apollo/client';


export const getProcesses = gql`
	query processes {
		processes { 
			id 
			name 
			seller {
				id
				name
			}
			workflowItems {
				id
      	name
      	phases {
      		id
        	name
				}
		}
		bundle { id name goods { name } }
		externalId { id externalId }
	}
} `

export const getProcess = gql`
  query process($id: Int!) {
    process(id: $id) { 
      id
      name
      email
      externalId {
        externalId
      }
      workflowItems {
        id
        name
				description
        phases {
          id
          name
          priority
          steps {
            id
            name
		        priority
		        description
		        externalIds {
			        externalId
		        }
          }
        }
      }
    }
  }
`;
