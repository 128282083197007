import { TextField, Grid, Box, Typography, Button } from '@mui/material';
import _ from 'lodash';
import { SelectedNode } from '../superadmin.types';
import { useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { UPDATE_PROCESS_STEP } from '../../../mutations/process';
import CustomSnackbar from '../../common/CustomSnackbar';

interface NodeSelectedProps {
  id: string;
  data: SelectedNode 
  measured: {
    width: number;
    height: number;
  };
  position: {
    x: number;
    y: number;
  };
  type: string;
}

const nodeFormFields = [
  { label: 'Name', name: 'name', type: 'text', required: false },
  { label: 'Description', name: 'description', type: 'text', required: false },
  { label: 'Priority', name: 'priority', type: 'number', required: false },
  { label: 'ExternalId', name: 'externalId', type: 'text', required: false },
];

const defaultNodeData: SelectedNode = {
  id: '',
  name: '',
  priority: 0,
  description: '',
  expandable: false,
  expanded: false,
  color: '',
};

type FormData = {
  name: string;
  description: string;
  priority: number;
  externalId: string;
};

const NodeInfoForm =  ({ nodeSelectedData }: { nodeSelectedData: NodeSelectedProps })  => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(true); 
  const [updateProcessStep, ] = useMutation(UPDATE_PROCESS_STEP)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    isSuccess: true,
  });

  const nodeData = _.get(nodeSelectedData, 'data', defaultNodeData)

  const [formData, setFormData] = useState<FormData>({
    name: nodeData.name,
    description: nodeData.description,
    priority: nodeData.priority,
    externalId: '',
  });


  const handleSave = async () => {
    if (!isEditing) {
      setIsDisabled(false);
      setIsEditing(true);
      return;
    }
  
    if (nodeSelectedData.id.includes('step')) {
      const processStepData = {
        id: nodeSelectedData.data.id,
        name: formData.name,
        description: formData.description,
        priority: formData.priority,
      };
  
      try {
        await updateProcessStep({
          variables: {
            processStep: processStepData,
          },
        });
        setSnackbarData({
          message: 'Process step updated successfully!',
          isSuccess: true,
        });
      } catch (error) {
        setSnackbarData({
          message: 'Failed to update the process step. Please try again.',
          isSuccess: false,
        });
      }
  
      setSnackbarOpen(true);
      setIsDisabled(true);
      setIsEditing(false);
    } else {
      setIsDisabled(false);
    }
  };
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  

  const handleCancel = () => {
    setIsDisabled(true);
    setIsEditing(false)


  };

  useEffect(() => {
    if (nodeData.id !== null && nodeData.name.length > 0) {
      setIsDisabled(false);
    }
    setFormData({
      name: nodeData.name,
      description: nodeData.description,
      priority: nodeData.priority,
      externalId: '',
    });
  }, [nodeData]);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
  

  return (
    <Box component="form" sx={{ padding: 2 }}>
      <Typography variant="h6">
        {nodeData?.name 
          ? ` ${nodeData?.name || ''}`
          : 'Lock and select an item'}
      </Typography>

      <Grid container spacing={2}>
        {nodeFormFields.map((field, index) => (
          <Grid item xs={12} key={index}>
            <TextField
              label={field.label}
              variant="outlined"
              type={field.type || 'text'}
              disabled={isDisabled}
              fullWidth
              required={field.required || false}
              name={field.name}
              value={formData[field.name as keyof FormData] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 2 }}>

        <Button onClick={handleSave}>{isEditing ? 'Save' : 'Edit'}</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        isSuccess={snackbarData.isSuccess}
      />
    </Box>
  );
};

export default NodeInfoForm;
