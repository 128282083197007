import { Handle, NodeProps, Position} from '@xyflow/react';
import { ExpandCollapseNode } from './types';
import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CustomNode({
  data,
}: NodeProps<ExpandCollapseNode>) {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    setIsSelected(!isSelected);
  };

    return (
      <div>
        <Box
          onClick={handleClick}
          sx={{
            position: 'relative',
            border: `1px solid ${data.color || '#000'}`,
            padding: 1,
            borderRadius: 3,
            boxShadow: 3,
            backgroundColor: '#f5f5f5',
            textAlign: 'center',
            width: '165px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            maxHeight: '100px',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: 'secondary.main',
              transform: 'scale(1.05)',
              boxShadow: 3,
            },
          }}
        >

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >

            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '14px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                flex: 8,
              }}
            >
              {data.name || ''}
            </Typography>

            {data.expandable && (
              <IconButton
                size="small"
                sx={{
                  flex: 2,
                  transition: 'transform 0.3s',
                  transform: data.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              >
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            )}
          </Box>

          {data.priority && (
            <Typography sx={{ fontSize: '12px', marginTop: 1 }}>
              Priority: {data.priority}
            </Typography>
          )}

          <Handle position={Position.Top} type="target" />
          <Handle position={Position.Bottom} type="source" />
        </Box>
      </div>
    );
  }