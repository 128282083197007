import { useNavigate, useParams } from "react-router-dom";
import ReactFlowWrapper from "./ReactFlowViewProcesses";
import { getProcess } from "../../../queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import CircularProgressBackdropSmall from "../../common/CircularProgressBackdropSmall";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import NodeInfoForm from "./SelectedNodeForm";

interface NodeData {
  color: string;
  description: string;
  expandable: boolean;
  expanded: boolean;
  externalId: string;
  id: string;
  name: string;
  priority: number;
}
interface NodeSelected {
  id: string;
  data: NodeData
  measured: {
    width: number;
    height: number;
  };
  position: {
    x: number;
    y: number;
  };
  type: string
}

const ProcessPage = () => {
  const { processId } = useParams();
  const navigate = useNavigate();
  const [locked, setLocked] = useState(false);
  const [nodeSelectedData, setNodeSelectedData] = useState<NodeSelected | null>(null)
  
  const toggleLock = () => {
    setLocked((prevLocked) => !prevLocked);
  };

  const {
    loading: isQueryLoading,
    error,
    data: queryData,
  } = useQuery(getProcess, {
    variables: { id: Number(processId) },
  });

  if (isQueryLoading) {
    return <CircularProgressBackdropSmall />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error loading process
      </Typography>
    );
  }

  const process = _.get(queryData, 'process', {});

  return (
    <Grid container direction="column" sx={{ height: 550, padding: 2, mt: 10 }}>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton
            onClick={() => navigate('/superadmin/processes')}
            sx={{ color: 'navy' }}
            disableRipple
          >
            <ArrowBack />
          </IconButton>
          <Typography
            variant="overline"
            color="textPrimary"
            sx={{ ml: 1, cursor: 'pointer' }}
            onClick={() => navigate('/superadmin/processes')}
          >
            Back to Processes
          </Typography>
        </Box>
      </Grid>

      <Grid container item spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={4}>
          {nodeSelectedData ? (
            <NodeInfoForm nodeSelectedData={nodeSelectedData} />
          ) : (
            <p>Please select a node to view details.</p>
          )}
        </Grid>


        <Grid item xs={8}>
          <Box sx={{ bgcolor: 'white', height: '100%', overflow:'hidden' }}>
            <Button
              size="small"
              variant="outlined"
              onClick={toggleLock}
              style={{
                color: locked ? 'gray' : 'navy',
                borderColor: locked ? 'gray' : 'navy',
              }}
            >
              {locked ? 'Unlock Nodes' : 'Lock Nodes'}
            </Button>

            <ReactFlowWrapper
              process={process}
              setNodeSelectedData={setNodeSelectedData}
              locked={locked}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}


export default ProcessPage;
