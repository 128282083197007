import { gql } from '@apollo/client'


export const UPDATE_PROCESS_STEP = gql `
  mutation updateProcessStep($processStep: ProcessStepUpdateInput!) {
    updateProcessStep(processStep: $processStep) {
    id
    description
    name
    priority 

      }
    }

`
